import * as React from "react";
import PropTypes from "prop-types";
import { graphql, Link, } from "gatsby";
import Layout from "../components/Layout";
import BlobImageComponent from '../components/ImageWithText';

class NotFoundPage extends React.Component{
  constructor(props) {
    super(props);
  }
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    const randomNumber = Math.floor(Math.random() * posts.length);
    const randomProject = posts[randomNumber];
    return(
      <Layout>
        <div className="section">
          <div className="container content">
            <h1>This page doesn't exist</h1>
            <p>Check that you typed the address correctly.
              <br></br>
              Go back to the previous page or click here to go to a random project.
              <br></br>
            </p>
            <div className="is-pulled-right">
            <Link className="pointer" to={randomProject.node.fields.slug}>
            <BlobImageComponent
                    index={0}
                    title="random project"
                    textSize={20}
                    width={250}
                    height={250}
                  />
              <br></br>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default NotFoundPage;

export const notFoundQuery = graphql`
  query randomProjectsQuery{
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      ) {
      edges {
        node {
          fields{
            slug
          }
          id
          frontmatter {
            title
          }
        }
      }
    }
  }
`;